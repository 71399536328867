<template>
  <div>
    <div class="page-content py-5">
      <div class="container">
        <result :payment_id="localPaymentId"></result>
      </div>
    </div>
  </div>
</template>

<script>
import Result from "@/components/Result";

export default {
  name: "ResultPage",
  data() {
    return {
      localPaymentId: this.$route.query.payment_id, //this.$route.query("payment_id")
    };
  },
  components: { Result },
  created() {
    //console.log(this.$route.query.payment_id);
  },
  metaInfo() {
    return {
      titleTemplate: this.$t("title.result") + " | %s",
      meta: [{ property: "og:title", content: this.$t("title.result") }],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div
    class="
      edc-content-round
      my-donations-content
      rounded-50
      col-md-8 col-sm-12
      m-auto
      p-3 p-lg-5
    "
  >
    <loading-page :isShow="isShowLoading"></loading-page>
    <div v-if="hasError">
      <div class="alert alert-danger">
        {{ $t("message.no_any_payment_found") }}
      </div>
    </div>
    <div v-if="!hasError">
      <div
        class="payment-success text-center edc-color-green mb-4"
        v-if="result.payment.is_success"
      >
        <i class="fas fa-check-circle" style="font-size: 4rem"></i>
        <h1 class="mt-2">{{ $t("message.donate_success") }}</h1>
      </div>
      <div
        class="payment-error text-center edc-color-red"
        v-if="!result.payment.is_success && result.payment"
      >
        <i class="fas fa-times-circle" style="font-size: 5rem"></i>
        <h1 class="mt-2">{{ $t("message.donate_fail") }}</h1>
      </div>
      <!-- <button
        @click.prevent="tryAgain()"
        class="btn edc-btn-blue rounded-pill text-white px-3 py-2 float-start"
      >
        <i class="fas fa-check"></i>
        <span class="fs-10">
          {{ $t("message.donate_again") }}
        </span>
      </button> -->

      <div v-if="result.projects" class="payment-details mt-5">
        <h3 class="edc-color-primary fs-2 fw-bold edc-section-title">
          {{ $t("title.projects") }}
        </h3>
        <!--my-donations-projects-item-->
        <div
          v-for="(item, index) in result.projects"
          :key="index"
          class="
            my-donations-projects-item
            d-lg-flex
            justify-content-lg-between
            align-items-start
            border-bottom
            mt-4
            py-3
          "
        >
          <div class="thumable me-2 col-lg-2">
            <router-link :to="{ path: 'products/' + item.slug }">
              <div
                v-if="item.is_completed"
                class="project-completed fs-7 rounded-pill edc-color-green"
              >
                <i class="fas fa-check"></i>
                <span class="d-inline ms-2">{{ $t("btn.donated") }}</span>
              </div>
              <img
                :src="item.image.thumbnail"
                lazysizes
                :data-src="item.image.thumbnail"
                class="project-list-img img-fluid h-50"
                alt=""
                srcset=""
              />
            </router-link>
          </div>
          <div class="details flex-fill mt-3 mt-lg-0">
            <h2 class="text-end fs-6 fw-bold mb-4">
              <router-link :to="{ path: 'products/' + item.slug }">{{
                item.title
              }}</router-link>
            </h2>
            <!--progress-->
            <progress-and-details
              :project_id="parseInt(item.id)"
              :details="item.meta"
              :amount="parseInt(item.amount)"
            ></progress-and-details>

            <!--// progress-->

            <!--buttons-->

            <div class="d-flex">
              <div
                v-if="item.reports"
                class="
                  buttons
                  mt-4
                  d-lg-flex
                  justify-content-lg-between
                  align-items-lg-center
                "
              >
                <a
                  href="#"
                  class="
                    rounded-pill
                    d-block d-lg-inline
                    btn
                    edc-btn-primary
                    text-white
                    fs-5
                    py-2
                    px-4
                    text-center
                  "
                >
                  {{ $t("label.download_report") }}
                  <i class="fas fa-cloud-download-alt"></i>
                </a>
              </div>
              <div
                v-if="item.is_gift && result.payment.is_success"
                class="
                  buttons
                  mt-4
                  d-lg-flex
                  justify-content-lg-between
                  align-items-lg-center
                "
              >
                <button
                  @click.prevent="downloadCertificat()"
                  class="
                    rounded-pill
                    d-block d-lg-inline
                    btn btn-success
                    text-white
                    fs-5
                    py-2
                    px-4
                    text-center
                  "
                >
                  {{ $t("label.certificat") }}
                  <i class="fas fa-cloud-download-alt"></i>
                </button>
              </div>
            </div>
            <!--//buttons-->
          </div>
        </div>
        <!--//my-donations-projects-item-->
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <button
          v-if="result.payment"
          @click.prevent="printInvoice()"
          class="btn edc-btn-blue rounded-pill text-white px-3 py-2 float-start"
        >
          <i class="fas fa-print"></i>
          <span class="fs-10">
            {{ $t("btn.print") }}
          </span>
        </button>
        <a
          v-if="result.payment.is_success && isMobile()"
          href="whatsapp://send?phone=96597288044&text=اود الاشتراك بخدمة تقارير لجنة الدعوة الالكتورنية"
          class="
            btn
            mx-2
            edc-btn-blue
            rounded-pill
            text-white
            px-3
            py-2
            float-start
          "
        >
          <i class="fab fa-whatsapp"></i>
          <span class="fs-10">
            {{ $t("btn.subscribe_reporting_service") }}
          </span>
        </a>
      </div>
      <div class="payment-details mt-5" id="printInvoice" v-if="result.payment">
        <h3 class="edc-color-primary fs-2 fw-bold edc-section-title">
          {{ $t("label.payment_details") }}
        </h3>
        <table class="table mt-3">
          <tbody>
            <tr>
              <td>{{ $t("label.donate_status") }}</td>
              <td>{{ result.payment.result }}</td>
            </tr>
            <tr>
              <th>{{ $t("label.donate_gateway") }}</th>
              <td>
                {{ result.payment.gateway }}
                <img
                  :src="result.payment.icon"
                  alt="result.payment.gateway"
                  width="30px"
                />
              </td>
            </tr>

            <tr>
              <th>{{ $t("label.amount") }}</th>
              <td>{{ result.total }} {{ result.currency }}</td>
            </tr>
            <tr v-if="result.payment.payment_id">
              <th>{{ $t("label.payment_id") }}</th>
              <td>{{ result.payment.payment_id }}</td>
            </tr>
            <tr v-if="result.payment.track_id">
              <th>{{ $t("label.track_id") }}</th>
              <td>{{ result.payment.track_id }}</td>
            </tr>
            <tr v-if="result.payment.reference_id">
              <th>{{ $t("label.reference_id") }}</th>
              <td>{{ result.payment.reference_id }}</td>
            </tr>
            <tr v-if="result.payment.transaction_id">
              <th>{{ $t("label.transaction_id") }}</th>
              <td>{{ result.payment.transaction_id }}</td>
            </tr>
            <tr v-if="result.payment.created">
              <th>{{ $t("label.created_at") }}</th>
              <td>{{ result.payment.created }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
 <script>
import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";
import ProgressAndDetails from "@/components/ProgressAndDetails";
import axios from "axios";
import LoadingPage from "@/components/LoadingPage.vue";
import vueLazysizes from "vue-lazysizes";

Vue.use(VueHtmlToPaper, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  windowTitle: window.document.title,
});
export default {
  //Vue.component("result-page",
  name: "result",
  components: { ProgressAndDetails, LoadingPage },
  props: {
    payment_id: "",
  },
  data() {
    return {
      result: {
        currency: "",
        payment: "",
        projects: "",
        total: "0",
        user: "",
      },
      hasError: false,
      isShowLoading: true,
    };
  },
  methods: {
    async get_result() {
      this.isShowLoading = false;
      await axios
        .post("result", {
          payment_id: this.payment_id,
        })
        .then((response) => {
          this.result = response.data;
          
        })
        .catch((error) => {
          this.hasError = true;
        });
    },
    async downloadCertificat() {
      //console.log("payment_id", this.payment_id);
      const url = "/?download-waqf-certificat=" + this.payment_id;
      window.location.href = url;
    },
    async tryAgain() {
      let response = await axios
        .post("re-donate", {
          payment_id: this.payment_id,
        })
        .then((response) => {
          if (response.data.status === "success") {
            location.href = response.data.url;
          } else {
            this.$swal({
              icon: "error",
              title: response.data.msg,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        })
        .catch((error) => {
          //console.log("Donate" + error);
        });
    },
    printInvoice() {
      // Pass the element id here
      this.$htmlToPaper("printInvoice");
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.get_result();
  },
  directives: {
    lazysizes: vueLazysizes
  },
  watch: {
    total: (newData) => {
      if (newData <= 0) {
        this.hasError = true;
      }
    },
  },
};
</script>